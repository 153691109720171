<!-- start language button for larg screens -->
<button mat-button class="menu-card btn lang-btn" *ngIf="currentLang == 'en'" (click)="useLanguage('ar')">
  <mat-icon class="trans-icon">language</mat-icon>
  <span>العربية</span>
</button>

<button mat-button class="menu-card btn lang-btn" *ngIf="currentLang == 'ar'" (click)="useLanguage('en')">
  <span>English</span>
  <mat-icon class="trans-icon">language</mat-icon>
</button>
<!-- start language menu for mobile screens -->
<!-- <button mat-button [matMenuTriggerFor]="langMenu" class="menu-card btn  lang-menu-btn">
  <mat-icon class="trans-icon">language</mat-icon>
</button>
<mat-menu #langMenu="matMenu">
  <button (click)="useLanguage('en')" mat-menu-item>
    <span *ngIf="currentLang == 'ar'">الإنجليزية</span>
    <span *ngIf="currentLang == 'en'">English</span>
  </button>
  <button (click)="useLanguage('ar')" mat-menu-item>
    <span *ngIf="currentLang == 'ar'">العربية</span>
    <span *ngIf="currentLang == 'en'">Arabic</span>
  </button>
</mat-menu> -->
