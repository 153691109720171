import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const StudentssRoutes: Route[] = [
  {
    path: "students",
    loadComponent: () =>
      import("../../Feature/students/pages/students-list/students-list.component").then(
        (mod) => mod.StudentsListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "students/:id/wallet",
    loadComponent: () =>
      import("../../Feature/students/pages/student-wallet/student-wallet.component").then(
        (mod) => mod.StudentWalletComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
