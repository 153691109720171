import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const TeachersRoutes: Route[] = [
  {
    path: "teachers/all",
    loadComponent: () =>
      import("../../Feature/teachers/pages/teacher-list/teacher-list.component").then(
        (mod) => mod.TeacherListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/active-teachers",
    loadComponent: () =>
      import("../../Feature/teachers/pages/active-teacher/active-teacher.component").then(
        (mod) => mod.ActiveTeacherComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/inactive-teachers",
    loadComponent: () =>
      import("../../Feature/teachers/pages/inactive-teacher/inactive-teacher.component").then(
        (mod) => mod.InactiveTeacherComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/:status/:teacherId/reservation",
    loadComponent: () =>
      import("../../Feature/teachers/pages/reservation-list/reservation-list.component").then(
        (mod) => mod.ReservationListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/:status/:teacherId/reservation/:id/members",
    loadComponent: () =>
      import("../../Feature/teachers/pages/members-meeting/members-meeting.component").then(
        (mod) => mod.MembersMeetingComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/:status/:id/wallet",
    loadComponent: () =>
      import("../../Feature/teachers/pages/teacher-wallet/teacher-wallet.component").then(
        (mod) => mod.TeacherWalletComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/:status/:id/meeting",
    loadComponent: () =>
      import("../../Feature/meetings/pages/meetings/meetings.component").then((mod) => mod.MeetingsComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "teachers/:status/:teacherId/meeting/:id/members",
    loadComponent: () =>
      import("../../Feature/meetings/pages/members-meeting/members-meeting.component").then(
        (mod) => mod.MembersMeetingComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
