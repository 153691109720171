import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Router, RouterLink } from "@angular/router";
import { Location } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { ConfirmDialogComponent } from "src/app/shared/dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PlatformService } from "src/app/core/services/platform.service";
import { firstValueFrom } from "rxjs";
import { UserService } from "@kortobaa-front/authentication";

@Component({
  selector: "app-side-menu",
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule, MatIconModule],
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent {
  @Output() toggleSubMenu: EventEmitter<any> = new EventEmitter();
  @Input() sideNavToggleBtn!: boolean;
  @Input() subMenu!: boolean;
  openFlag: boolean = false;
  routeTitle: string = "";
  route!: any;
  sideMenu = [
    {
      id: "home",
      title: "home",
      route: "/home",
      image: `../../../../assets/images/side-menu/home`,
      icon: "home",
      hasChildren: false,
      children: [] as any,
    },
    {
      id: "students",
      title: "students",
      route: "/students",
      image: `../../../../assets/images/side-menu/consumer`,
      icon: "admin_panel_settings",
      hasChildren: false,
    },
    {
      title: "requests-join",
      route: "/requests-join/active-apps",
      image: `../../../../assets/images/side-menu/request-join`,
      icon: "group_add",
      hasChildren: true,
      id: "requests",
      children: [
        {
          title: "pending-apps",
          route: "/requests-join/pending-apps",
        },
        {
          title: "active-apps",
          route: "/requests-join/active-apps",
        },
        {
          title: "declined-apps",
          route: "/requests-join/declined-apps",
        },
      ],
    },
    {
      title: "modification-requests",
      route: "/modification-requests/available-requests",
      image: `../../../../assets/images/side-menu/modification`,
      icon: "edit_square",
      hasChildren: true,
      id: "modification",
      children: [
        {
          title: "available-requests",
          route: "/modification-requests/available-requests",
        },
        {
          title: "rejected-requests",
          route: "/modification-requests/rejected-requests",
        },
      ],
    },
    {
      id: "teachers",
      title: "teachers",
      route: "/teachers/all",
      image: `../../../../assets/images/side-menu/verified`,
      icon: "verified",
      hasChildren: false,
      // children: [
      //   {
      //     title: "all",
      //     route: "/teachers/all",
      //   },
      //   {
      //     title: "active-teachers",
      //     route: "/teachers/active-teachers",
      //   },
      //   {
      //     title: "inactive-teachers",
      //     route: "/teachers/inactive-teachers",
      //   },
      // ],
    },
    // {
    //   title: "meetings",
    //   route: "/meetings",
    //   image: `../../../../assets/images/side-menu/meetings`,
    //   icon: "groups",
    //   hasChildren: true,
    //   id: "meetings",
    //   children: [
    //     {
    //       title: "active-meetings",
    //       route: "/meetings/active-meetings",
    //     },
    //     {
    //       title: "scheduled-meetings",
    //       route: "/meetings/scheduled-meetings",
    //     },
    //     {
    //       title: "ended-meetings",
    //       route: "/meetings/ended-meetings",
    //     },
    //   ],
    // },
    {
      title: "payments",
      route: "/payments",
      image: `../../../../assets/images/side-menu/payments`,
      icon: "payments",
      hasChildren: false,
      condition: true,
    },
    {
      title: "subscription",
      route: "/subscription",
      icon: "loyalty",
      hasChildren: false,
      condition: true,
    },
    {
      title: "money-transfer-requests",
      route: "/money-transfer-requests",
      image: `../../../../assets/images/side-menu/money-transfer-requests`,
      icon: "request_page",
      hasChildren: false,
      condition: true,
    },
    // {
    //   title: "money-transfer-requests",
    //   route: "/money-transfer-requests",
    //   image: `../../../../assets/images/side-menu/money-transfer-requests`,
    //   icon: "request_page",
    //   hasChildren: false,
    //   condition: true,
    // },
    {
      id: "notifications",
      title: "notifications",
      route: "/notifications/teachers-notifications",
      image: `../../../../assets/images/side-menu/notifications`,
      icon: "notifications",
      hasChildren: true,
      children: [
        {
          title: "teacher-notifications",
          route: "/notifications/teacher-notifications",
        },
        {
          title: "student-notifications",
          route: "/notifications/student-notifications",
        },
      ],
    },
    {
      id: "coupons",
      title: "coupons",
      route: "/coupons/active-coupons",
      image: `../../../../assets/images/side-menu/notifications`,
      icon: "sell",
      hasChildren: true,
      children: [
        {
          title: "active-coupons",
          route: "/coupons/active-coupons",
        },
        {
          title: "expire-coupons",
          route: "/coupons/expire-coupons",
        },
      ],
    },
    {
      id: "reports",
      title: "reports",
      route: "/reports/error-reports",
      image: `../../../../assets/images/side-menu/reports`,
      icon: "report",
      hasChildren: true,
      children: [
        {
          title: "error-reports",
          route: "/reports/error-reports",
        },
        {
          title: "abuse-reports",
          route: "/reports/abuse-reports",
        },
        {
          title: "claims",
          route: "/reports/claims",
        },
      ],
    },
    {
      id: "evaluations",
      title: "evaluations",
      route: "/evaluations",
      image: `../../../../assets/images/side-menu/evaluations`,
      icon: "reviews",
      hasChildren: false,
    },
    {
      id: "pages",
      title: "pages",
      route: "/pages/about-app",
      image: `../../../../assets/images/side-menu/cms`,
      icon: "support",
      hasChildren: true,
      children: [
        {
          title: "about-app",
          route: "/pages/about-app",
        },
        {
          title: "terms-conditions",
          route: "/pages/terms-conditions",
        },
        {
          title: "privacy-policy",
          route: "/pages/privacy-policy",
        },
        {
          title: "faqs",
          route: "/pages/faqs",
        },
      ],
    },
    // {
    //   id: "update-version",
    //   title: "update-version",
    //   route: "/update-version",
    //   image: `../../../../assets/images/side-menu/version`,
    //   icon: "barcode",
    //   hasChildren: false,
    // },
  ];
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();
  secondaryColor = "var(--primary-color)";
  activeChildRoute!: string;

  constructor(
    private _router: Router,
    location: Location,
    private _dialog: MatDialog,
    private _user: UserService,
    private _snackBar: MatSnackBar,
    // private _firebase: FirebaseService,
    public _platForm: PlatformService,
    public _translate: TranslateService
  ) {
    this._router.events.subscribe((val) => {
      if (location.path() != "") {
        let routing = location.path().split("/");
        this.route = location.path();
        this.activeChildRoute = routing[routing.length - 1];
      }
    });
  }

  //toggle function to open and close sub menu in side nav bar
  toggle(value: boolean, openFlag: boolean, title?: string) {
    if (value && this.routeTitle != title) {
      value = true;
      if (title) this.routeTitle = title;
    } else {
      value = false;
      this.routeTitle = "";
    }
    this.openFlag = value;
    this.subMenu = value;
    this.toggleSubMenu.emit(value);
  }

  // go to pages
  goTo(route: string) {
    // check when side nav is close close sub menu when route
    if (this.sideNavToggleBtn) {
      this.toggle(false, false);
    }
    this._router.navigateByUrl(route);
    this.sideNavToggleBtnRes.emit(false);
  }

  // logout
  async logout() {
    const isLogout: boolean = await firstValueFrom(
      this._dialog
        .open(ConfirmDialogComponent, {
          width: "26.25rem",
          data: {
            title: this._translate.instant("login.logout"),
            content: this._translate.instant("login.are-you-want-logout"),
            action: { accept: "yes", refuse: "cancel", color: this.secondaryColor },
          },
        })
        .afterClosed()
    );

    if (isLogout) {
      this._snackBar
        .open(this._translate.instant("confirm-dialog.logout-message"), "", {
          panelClass: "success-snackbar",
          duration: 1500,
        })
        .afterDismissed()
        .subscribe(() => {
          // this._firebase.logout().subscribe();
          this._user.logout();
          this._router.navigate(["/login"]);
          this._dialog.closeAll();
        });
    }
  }
}
